@import '../../scss/vars';

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    &__footer {
        text-align: center;
        width: 100%;
        font-size: 15px;
        a {
            color: $secondary;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
        }
    }

    &__divider {
        font-size: 18px;
        margin-bottom: 20px;
    }
}

.input {
    display: block;
    width: 100%;
    border: 0;
    outline: 0;
    padding: 8px 12px;
    background-color: $primary;
    color: $secondary;
    border-bottom: 2px solid $secondary;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    transition: border 0.2s linear;

    &__label {
        width: 100%;

        & + .input__label {
            margin-top: 40px;
        }
        & + .checkbox__label {
            margin-top: 40px;
        }
    }

    &.error {
        border-bottom-color: tomato;
    }
}

.error-message {
    width: 100%;
    font-size: 12px;
    color: tomato;
}

.checkbox {
    margin-right: 10px;
    background-color: #888;
    &__label {
        width: 100%;
        font-size: 18px;
        color: #888;
        & + .button {
            margin-top: 40px;
        }

        a {
            display: inline;
            color: #888;
            transition: color 0.2s linear;
            &:hover {
                color: $secondary;
            }
        }
    }
    &.error {
        outline: 2px solid tomato;
    }
}

.button {
    display: block;
    width: 100%;
    border: 1px solid $secondary;
    outline: 0;
    background-color: $secondary;
    color: #000;
    padding: 12px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    cursor: pointer;
    transition: background 0.2s linear, color 0.2s linear;

    &:hover {
        background-color: darken($secondary, 15%);
        color: #000;
    }

    &--outline {
        background-color: $primary;
        color: $secondary;

        &:hover {
            background-color: #fff;
            color: #000;
        }
    }
}

// media
@media (max-width: $sm) {
    .form {
        width: 100%;
    }
}
