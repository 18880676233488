@import '../../scss/vars';
.product-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    transition: box-shadow 0.2s linear;

    &:hover {
        box-shadow: 0 0 10px 5px rgba(#000, 0.5);
    }
    a {
        flex: 1 0 auto;
        color: $secondary;
        text-decoration: none;
    }

    &__image {
        display: block;
        max-width: 100%;
        max-height: 200px;
        margin: 0 auto;
    }

    &__title {
        flex: 1 0 auto;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
